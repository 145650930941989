/**
 * Page layout for all screens if user is logged in.
 */
import {Navigate, NavLink, Outlet, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import rtasksLogo from '../images/vault_logo.svg';
import EnforceAuthentication from '../user/EnforceAuthentication';

const AuthenticatedPageLayout = () => {
    const {t} = useTranslation();
    const location = useLocation();

    // Redirect to downloads route if
    // user is stuck on parent route.
    if (location.pathname === '/') {
        return (<Navigate to="/downloads" replace/>);
    }

    return (
        <>
            <EnforceAuthentication/>
            <div className="min-h-screen bg-gray-50">
                <nav className="bg-white border-b border-gray-200">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <img className="block h-8 w-auto" src={rtasksLogo} alt="RTasks"/>
                                </div>
                            </div>
                            <div className="mt-6 mb-6 flex items-center">
                                <div>
                                    <div>
                                        <NavLink
                                            to="/logout"
                                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            {t('Sign out')}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="py-10">
                    <main>
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div className="px-4 py-8 sm:px-0">
                                <Outlet/>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default AuthenticatedPageLayout;
