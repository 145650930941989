/**
 * Main component that sets up state and handles routing.
 */
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import AuthenticatedPageLayout from './layout/AuthenticatedPageLayout';
import DownloadList from './download/DownloadList';
import Forbidden from './error/Forbidden';
import Login from './user/Login';
import Logout from './user/Logout';
import NotFound from './error/NotFound';

const App = () => (
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/" element={<AuthenticatedPageLayout/>}>
                    <Route path="/downloads" element={<DownloadList/>}/>
                </Route>
                <Route path="/forbidden" element={<Forbidden/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    </>
);

export default App;
