import Cookie from 'universal-cookie';

const cookie = new Cookie();

const getDomainWithoutSubdomain = () => {
    const urlParts = (window.location.hostname).split('.');
    
    if (urlParts.length === 3) {
        return `${urlParts[1]}.${urlParts[2]}`;   // `*.${urlParts[1]}.${urlParts[2]}`     `.${urlParts[1]}.${urlParts[2]}`
    } else {
        return urlParts.join('.');
    }
}

export const getCookie = (key) => {
    return cookie.get(key, { path: '/', domain: getDomainWithoutSubdomain() });
}

export const setCookie = (key, value) => {
    return cookie.set(key, value, { path: '/', domain: getDomainWithoutSubdomain() });
}

export const removeCookie = (key) => {
    return cookie.remove(key, { path: '/', domain: getDomainWithoutSubdomain() });
}

export const addCookieListener = (callback) => {
    return cookie.addChangeListener(callback);
}