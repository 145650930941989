/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Download list screen.
 */
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import sortBy from 'lodash/sortBy';

import axios from '../axios';

const DownloadList = () => {
    const {t} = useTranslation();
    const [downloadList, setDownloadList] = useState();
    const [searchQuery, setSearchQuery] = useState('');

    // Get list of downloads
    useEffect(() => {
        const refreshDownloadList = async () => {
            try {
                const {data} = await axios.get('/downloads');
                setDownloadList(sortBy(data, ['client', 'campus']));
            } catch (err) {
            }
        };

        refreshDownloadList();
    }, []);

    // log file download
    const logDownload = async (clientId, campusId, typeId, name) => {
        try {
            const response = await axios.post('/log', {clientId, campusId, typeId});
            var link = document.createElement("a");
            link.href = response["data"].url;
            link.download = name;
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
        }
    };

    // Loading screen
    if (!downloadList) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="content-center">
                    <svg width="100" height="75" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M558.363 552.929H242.102L321.176 415.994L376.027 320.958H424.274V320.685L479.307 415.994L558.363 552.929Z"
                            fill="#C4B6FF"></path>
                        <path
                            d="M558.363 47.0706L479.307 184.024L424.274 279.333V279.041H376.027L321.176 184.024L242.102 47.0706H558.363Z"
                            fill="#B5CDFB"></path>
                        <path
                            d="M412.331 299.996L424.279 320.681V320.955H376.031L388.143 299.996L376.031 279.037L440.43 250.704L412.331 299.996Z"
                            fill="#C4B6FF"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M162 562.709C162 547.636 174.219 535.418 189.291 535.418H611.169C626.242 535.418 638.461 547.636 638.461 562.709C638.461 577.781 626.242 590 611.169 590H189.291C174.219 590 162 577.781 162 562.709Z"
                              fill="#7D55FF"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M162 37.2911C162 22.2186 174.219 10 189.291 10H611.17C626.242 10 638.461 22.2186 638.461 37.2911C638.461 52.3635 626.242 64.5822 611.17 64.5822H189.291C174.219 64.5822 162 52.3635 162 37.2911Z"
                              fill="#7D55FF"></path>
                    </svg>
                </div>
            </div>
        );
    }

    const filteredDownloadList = downloadList.filter((item) => item.client.toLowerCase().includes(searchQuery.toLowerCase()) || item.campus.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <>
            <div className="pb-5 border-b border-gray-200">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    {t('Emergency Reports')}
                </h3>
            </div>

            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <div className="flex-1 flex justify-between">
                                <div className="flex-1 flex">
                                    <div className="w-full flex md:ml-0">
                                        <label htmlFor="search_field" className="sr-only">Search</label>
                                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                            <div
                                                className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                                                aria-hidden="true">
                                            </div>
                                            <input
                                                id="search_field"
                                                name="search_field"
                                                className="block w-full h-full pl-6 pr-3 py-4 border-b-2 border-gray-100 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
                                                placeholder={t('Search') + '...'}
                                                type="search"
                                                autoComplete="off"
                                                value={searchQuery}
                                                onChange={(event) => setSearchQuery(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="sr-only">
                                <tr>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        {t('Campus')}
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 relative text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        {t('Downloads')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    filteredDownloadList.map((record) => (
                                        <tr key={record.id}>
                                            <td className="px-6 py-3">
                                                <div className="flex items-center">
                                                    <div>
                                                        <div className="text-sm font-medium text-gray-900">
                                                            {record.campus}
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {record.client}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 text-right">
                                                {record.reports.map((report) => (
                                                    <span key={report.id} className="mr-5">
                                                        <a
                                                            className="text-indigo-600 hover:text-indigo-700"
                                                            onClick={() => logDownload(record.clientId, record.campusId, report.id,report.name)}
                                                            style={{
                                                                cursor: 'pointer'/* Change cursor to pointer on hover */
                                                            }}
                                                            >
                                                            {report.name}
                                                        </a></span>
                                                ))}
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DownloadList;
