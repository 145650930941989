/**
 * Hook to watch local storage for changes.
 */
import {useEffect, useState} from 'react';
import { getCookie, addCookieListener, removeCookie } from "../cookieConfig";

const useCookieWatcher = (key) => {
    const [val, setVal] = useState(getCookie(key));

    useEffect(() => {

        if(!getCookie(key)){
            removeCookie(key);
        }
        // setup event listener.
        addCookieListener((a) => {
           if(a.value !== val){
               setVal(a.value);
           }
        });
    });

    useEffect(() => {
        setVal(getCookie(key));
    }, [key]);

    return val;
};

export default useCookieWatcher;
