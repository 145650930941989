/**
 * 403 access denied page.
 */
const Forbidden = () => (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="content-center">
            <svg width="400" height="300" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="10" y="119.635" width="780" height="398.52" fill="#C4B6FF"></rect>
                <path d="M356.203 137.515L405.587 81.8457L457.665 137.515" stroke="#005B9D" strokeWidth="3"></path>
                <rect x="27.8147" y="137.836" width="744.371" height="362.115" fill="#B5CDFB"></rect>
                <path d="M148.937 324.334C83.1847 299.96 40.792 320.949 27.8147 334.49V480.233H458.952H770.409V324.334C718.019 309.826 604.472 335.475 556.026 327.889C458.952 312.689 231.127 354.802 148.937 324.334Z" fill="#7D55FF"></path>
                <path d="M148.937 324.334C83.1847 299.96 40.792 320.949 27.8147 334.49V480.233H458.952H770.409V324.334C718.019 309.826 604.472 335.475 556.026 327.889C458.952 312.689 231.127 354.802 148.937 324.334Z" fill="white" fillOpacity="0.58"></path>
                <path d="M267.384 330.673C267.384 354.709 235.191 374.194 235.191 374.194C235.191 374.194 201.715 354.709 201.715 330.673C201.715 306.637 235.191 264.373 235.191 264.373C235.191 264.373 267.384 306.637 267.384 330.673Z" fill="#C4B6FF"></path>
                <rect x="228.508" y="363.164" width="12.0853" height="74.5667" fill="#C4B6FF"></rect>
                <path d="M483.873 330.674C483.873 354.71 451.705 374.196 451.705 374.196C451.705 374.196 418.256 354.71 418.256 330.674C418.256 306.637 451.705 264.373 451.705 264.373C451.705 264.373 483.873 306.637 483.873 330.674Z" fill="#C4B6FF"></path>
                <rect x="445.022" y="363.164" width="12.0853" height="74.5667" fill="#C4B6FF"></rect>
                <rect x="611.73" y="255.934" width="162.258" height="81.1712" fill="#C4B6FF"></rect>
                <rect x="600.264" y="308.896" width="173.724" height="105.907" fill="#C4B6FF"></rect>
                <path d="M148.937 404.962C83.1847 380.411 40.792 401.552 27.8147 415.192V499.952H458.952H770.409V404.962C718.019 390.349 604.472 416.184 556.026 408.543C458.952 393.232 231.127 435.651 148.937 404.962Z" fill="#7D55FF"></path>
                <path d="M175.17 280.348C175.17 319.396 122.913 351.05 122.913 351.05C122.913 351.05 68.5741 319.396 68.5741 280.348C68.5741 241.3 122.913 172.641 122.913 172.641C122.913 172.641 175.17 241.3 175.17 280.348Z" fill="#C4B6FF"></path>
                <rect x="113.286" y="335.365" width="17.173" height="105.958" fill="#C4B6FF"></rect>
                <path d="M398.82 280.348C398.82 319.396 346.563 351.05 346.563 351.05C346.563 351.05 292.224 319.396 292.224 280.348C292.224 241.3 346.563 172.641 346.563 172.641C346.563 172.641 398.82 241.3 398.82 280.348Z" fill="#C4B6FF"></path>
                <rect x="336.935" y="335.365" width="17.173" height="105.958" fill="#C4B6FF"></rect>
                <path d="M611.494 256.035L658.612 332.856H555.56L611.494 256.035Z" fill="#C4B6FF"></path>
                <rect x="696.07" y="234.828" width="31.2245" height="54.042" fill="#C4B6FF"></rect>
            </svg>
            <div className="text-center">
                <a className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150" href="https://rtasks.net">rtasks.net</a>
            </div>
        </div>
    </div>
);

export default Forbidden;
