/**
 * Application entry point.
 */
import React from 'react';
import ReactDOM from 'react-dom/client';

// Styles
import 'typeface-inter';
import './index.css';

// Translations
import './i18n';

import {makeServer} from './server';
import App from './App';

// Initialize mirage API for development
if (process.env.NODE_ENV === 'development') {
    makeServer({environment: 'development'});
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

