/**
 * Handle authentication state.
 */
import {useEffect} from 'react';
import {Navigate} from 'react-router-dom';

import axios from '../axios';
import { setCookie } from "../cookieConfig";
import useCookieWatcher from '../hooks/useCookieWatcher';

const EnforceAuthentication = () => {
    const authToken = useCookieWatcher('authToken');
    const isLoggedIn = !!authToken;

    // Refresh auth token every 5 minutes.
    useEffect(() => {
        // setup timed event.
        const interval = setInterval(async () => {
            try {
                const {data} = await axios.get('/token');
                setCookie('authToken', data.authToken);
            } catch (err) {
            }
        }, 5 * 60 * 1000);

        // cleanup event
        return () => clearInterval(interval);
    });

    // redirect to login page if user is logged out
    if (!isLoggedIn) {
        return (<Navigate to="/login"/>);
    }

    return (<></>);
};

export default EnforceAuthentication;
