/**
 * Axios configuration.
 */
import axios from 'axios';
import { getCookie, removeCookie } from "./cookieConfig";

axios.defaults.baseURL = '/api';

axios.interceptors.request.use((config) => {
    // Add Authorization header if token is present
    // in local storage.
    const token = getCookie('authToken');
    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token;
    }

    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Remove auth token if 401 is detected
        const {status} = error.response;
        if (status === 401) {
            removeCookie('authToken');
        }

        if (status === 403) {
            window.location.href = '/forbidden';
        }

        return Promise.reject(error);
    },
);

export default axios;
