/**
 * Logout screen.
 */
import {Navigate} from 'react-router-dom';
import {removeCookie} from '../cookieConfig';

const Logout = () => {
    removeCookie('authToken');
    return <Navigate to="/login" replace/>;
};

export default Logout;
